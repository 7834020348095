import { Route } from 'react-router-dom';
import Private from './Views/Private';
import Icons from "./assets";
import React from 'react';


{/* <input type="file" accept="image/*" capture="camera"></input> */}
//import { ReactComponent as partnerIcon } from "assets/img/menu/partner.svg";

export const LAYOUT_TYPE = {
  PRIVATE: "/private",
  PUBLIC: "/public",
}

interface RoutesInterface {
  path: string;
  layout: "/private"|"/public";
  name?: string;
  component: any;
  hidden?: boolean;
  Icon?: React.FC<{ color?: string }>|any;
}


const routes: RoutesInterface[] = [
  // PRIVATE
  // {
  //   path: "/offer",
  //   layout: "/private",
  //   name: "Demandes",
  //   Icon: incomingIcon,
  //   component: InComingRides,
  // },
  {
    path: "/new-ride",
    layout: "/private",
    name: "Réserver un transport",
    component: Private.NewRide,
    // Icon : Icons.Menu.NewRide,
   
  },
  {
    path: "/After",
    layout: "/private",
    name: "Réservation plus tard",
    component: Private.After,
    // Icon : Icons.Menu.NewRide, 
   
  },
  
  // {
  //   path: "/ongoing-rides",
  //   layout: "/private",
  //   name: "Réservation",
  //   component: Private.Ongoing, 
  //   Icon : Icons.Menu.Ongoing,// component c element
  // },
  
  // {

  //   path: "/historique",
  //   layout: "/private",
  //   name: "Historique des réservations",
  //   component: Private.RideHistory,
  //   Icon : Icons.Menu.History,
  // },
  {

    path: "/settings",
    layout: "/private",
    name: "Paramètres",
    component: Private.Settings,
  },
  {
    hidden: true,
    path: "*",
    layout: "/private",
    name: "page",
    component: Private.NewRide,
  },
  {
    hidden: true,
    path : "/QrCode",
    layout: "/private",
    name : "test Qr Code" ,
    
    component: Private.testQrCode,
  },
  {
    hidden: true,
    path : "/Attribution",
    layout: "/private",
    name : "Attribution en cours" ,
    
    component: Private.AssingementInProgress,
  },
  // PUBLIC

 


 
];

export function getRoutes(layout: '/private' | '/public') {
  return routes.map((prop, key) => {
    if (prop.layout === layout) {
      const View = prop.component;
      return (
        <Route
          path={prop.path}
          element={<View />}
          key={key}
        />
      );
    } else {
      return null;
    }
  });
}
export function getLink(layout : '/private' | '/public' ){
  return 
}

export default routes;