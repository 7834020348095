
import Settings from "./Settings";
import After from "./After";

import NewRide from "./NewRide";

import testQrCode from "./TestQrCode";
import AssingementInProgress from "./AssignementInProgress";
export default {

    Settings,
    AssingementInProgress,
    After,
    NewRide,
  
    testQrCode,
}