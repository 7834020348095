import { RideType } from './../globals/type';
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface CreateRideState {
  loading: boolean;
  error: string;
  ride: RideType|null
}


const initialState: CreateRideState = {
  ride: null,
  loading: false,
  error: "",
};

export const getRideSlice = createSlice({
  name: "getRide",
  initialState,
  reducers: {
    getRideRequest: (state, action: PayloadAction<string>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes.
      // Also, no return statement is required from these functions.
     
     
      state.loading = true;
      state.error = "";
    },
    getRideSuccess: (state, action: PayloadAction<RideType>) => {
      state.loading = false;
      state.ride=action.payload;
    },
    getRideFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    
    // updateWaitingTime: (state, action: PayloadAction<number>) => {
    //   state.ride?.waitingTime?.duration_in_traffic = action.payload;
    // },
  },
});

// Action creators are generated for each case reducer function
export const { getRideRequest, getRideSuccess, getRideFailure } =
  getRideSlice.actions;

export default getRideSlice.reducer;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value
