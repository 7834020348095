import { takeLatest } from "redux-saga/effects";
import { graphQLClient } from "../graphql";
import { PayloadAction } from "@reduxjs/toolkit";

import {
  putPresignedUrl,
  getPresignedUrl,
  IminioPayload,
} from "../reducers/minio";

function* getPresignedUrlAsync(action: PayloadAction<IminioPayload>) {
  const { callback, path } = action.payload;
  const query = `
    query {
      GetPresignedUrls(pathNames: ["${path.join('", "')}"])
    }
  `;

  try {
    const { GetPresignedUrls } = yield graphQLClient.request(query);
    callback(GetPresignedUrls);
  } catch (error) {
    callback(null);
  }
}

function* putPresignedUrlAsync(action: PayloadAction<IminioPayload>) {
  const { callback, path } = action.payload;
  const query = `
    query {
      PutPresignedUrl(pathName: "${path}")
    }
  `;
  try {
    const { PutPresignedUrl } = yield graphQLClient.request(query);
    callback(PutPresignedUrl);
  } catch (error) {
    callback(false);
  }
}

export default [
  takeLatest(putPresignedUrl, putPresignedUrlAsync),
  takeLatest(getPresignedUrl, getPresignedUrlAsync),
];
