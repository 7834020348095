import { FC } from "react";
import { Box } from "@mui/material";
import Private from "./private";

const Layout: FC = () => {
  return (
    <Box height="100vh" width="100%" display="flex" bgcolor={"#F9F8F8"}>
      <Private />
    </Box>
  );
};

export default Layout;
