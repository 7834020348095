import React, { useRef } from "react";
import QRCode from "qrcode.react";
import { Box, Button } from "@mui/material";
import html2canvas from "html2canvas";

const QRCodeComponent = () => {
  const appUrl = "https://6ced-195-154-25-118.ngrok-free.app"; 
  const qrCodeRef = useRef<HTMLDivElement>(null);

  const downloadQRCode = () => {
    if (qrCodeRef.current) {
      html2canvas(qrCodeRef.current).then((canvas) => {
        const link = document.createElement("a");
        link.href = canvas.toDataURL("image/png");
        link.download = "qrcode.png";
        link.click();
      });
    }
  };
  return (
    <Box>
      <Box ref={qrCodeRef} alignItems={'center'} display='flex' flexDirection={'column'} justifyContent={'ccenter'}>
        <h1>QR Code pour mon application</h1>
        <Box textAlign={'center'}>  <QRCode value={appUrl} size={200} /></Box>

       
        <p>
          Scannez ce code QR avec votre téléphone pour ouvrir l'application.
        </p>
      </Box>

      <Button onClick={downloadQRCode}>Télécharger QR Code</Button>
    </Box>
  );
};

export default QRCodeComponent;
