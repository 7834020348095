import { Box } from "@mui/material";
import { RideType } from "../../globals/type";
import Icon from "..//..//assets";
import { useNavigate } from "react-router-dom";

interface IAfter {
  ride: RideType;
}
const After: React.FC<IAfter> = ({ ride }) => {
    const navigate = useNavigate();

    const HandlaClickButton=()=>{
        navigate('/New-ride');
    }
  return (
    <Box
      display={"flex"}
      padding="20px 40px"
      gap="30px"
      flexGrow={1}
      flexDirection={"column"}
      bgcolor={"white"}
    >
      <Box display={"flex"} justifyContent={"center"}>
        <Icon.NewRideIcon.LogoResaam />
      </Box>

      <Box
        fontSize={"37px"}
        fontFamily={"lato"}
        textAlign={"center"}
        fontWeight={900}
      >
        Réservation confirmée{" "}
      </Box>

      <Box display={"flex"} flexDirection={"column"}>
        <Box display={"flex"} justifyContent={"center"}>
          <Icon.NewRideIcon.checkMarkIcon />
        </Box>
        <Box display="flex" justifyContent={"center"}>
          <Icon.NewRideIcon.VehicleIcon />
        </Box>
      </Box>

      <Box
        display={"flex"}
        flexDirection={"column"}
        gap="24px"
        textAlign={"center"}
      >
        <Box fontSize={"28px"} fontWeight={900} fontFamily={"lato"}>
          Vérifiez vos messages
        </Box>
        <Box>
          Nous vous enverrons un message de rappel 48h et 24h avant la date de
          votre transport.
        </Box>
      </Box>

      <Box
        display={"flex"}
        
        bgcolor={"primary.main"}
        padding="12px 0px"
        justifyContent={"center"}
        borderRadius={"5px"}
        sx={{cursor:"pointer"}}
        onClick={HandlaClickButton}
      >
        <Box color="white">Réserver un nouveau transport</Box>
      </Box>
      <Box
        display={"flex"}
        position={"absolute"}
        zIndex={3}
        height={"40%"}
        bgcolor={"primary.main"}
        borderRadius={"100px"}
        flexGrow={1}
      >
        {/* <Box><Icon.NewRideIcon.CircleBackGround /></Box> */}
        {/* <SvgIcon>
          <path d="M0 50 A50 50 0 0 0 100 50" />
        </SvgIcon> */}
      </Box>
    </Box>
  );
};
export default After;
