import React, { FC, useRef, useState } from "react";
import { Box } from "@mui/system";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";
import { InputBase } from "@material-ui/core";
import Icon from "../../assets";
import { VEHICULE_CATEGORY } from "../../globals/constantes";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDateTimePicker } from "@mui/x-date-pickers";
import * as Yup from "yup";

import { useNavigate } from "react-router-dom";
// import Camera from "..//..//components/Camera";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import Loading from "../../components/Loading";
import { createRideRequest } from "../../reducers/createRide";
import { AddressType } from "../../globals/type";
import InputAddress from "../../components/InputAdress";
// import QRCodeComponent from "../../components/QrCode";
import { putPresignedUrl } from "../../reducers/minio";
import { uploadFile } from "../../globals/functions";
// import OCRScanner from "../../components/OCRScanner";
// import ScannerComponent from '..//..//components/Numerate'
// import ImageScanner from "../../components/ImageScanner";

const MAX_HOURS_TO_SHOW_RIDE_TRACKING = 4;

const START_AT = {
  NOW: "now",
  AFTER: "after",
};
const TYPE = {
  SIMPLE: "simple",
  AR: "ar",
  SERIE: "serie",
};

interface IFormTabItem {
  label: string;
  isActive: boolean;
  onClick: () => void;
}

const FormTabItem: React.FC<IFormTabItem> = ({ label, isActive, onClick }) => (
  <Box
    flexGrow={1}
    display="flex"
    sx={{
      padding: {
        sm: "2px 2px",
        md: "2px 2px",
        lg: "2px 2px",
        xl: "2px 2px",
      },
    }}
  >
    <Box
      flexGrow={1}
      fontFamily={"lato"}
      fontSize="14px"
      fontWeight={700}
      lineHeight="17px"
      textAlign={"center"}
      borderRadius="5px"
      fontStyle="normal"
      border={isActive ? "2px solid" : "none"}
      color={isActive ? "white" : "#999C9F"}
      onClick={onClick}
      bgcolor={isActive ? "primary.main" : "#E5E5E5"}
      justifyContent="center"
      alignItems={"center"}
      display="flex"
      sx={{
        cursor: "pointer",
        ":hover": {
          borderColor: "#1976d2",
        },
      }}
      padding={"10px 0px"}
    >
      {label}
    </Box>
  </Box>
);

const NewRide: FC = () => {
  const { loading, error } = useAppSelector((state) => state.createRide);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const [inputValue, setInputValue] = useState("");
  const [isInputEmpty, setIsInputEmpty] = useState(false);

  const [isFillDeparture, setIsFillDeparture] = useState(false);
  const [departureAdress, setDepartureAdress] = useState<AddressType>({
    address: "",
    longitude: 0,
    latitude: 0,
  });
  const [arrived, setArrived] = useState<AddressType>();
  const [name, setName] = useState("");
  const [lastname, setLastName] = useState("");
  const [demarrer, setDemarrer] = useState("Plus tard");
  const [phoneNumber, setphoneNumber] = useState("");
  const [transportType, setTransportType] = useState<any>(null);
  const [vehicle, setVehicle] = useState("VSL");

  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());

  const [selectedFile, setSelectedFile] = useState<any>(null);

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };
  const pathSegments = location.pathname.split("/");

  const [state, setState] = useState({
    startAt: START_AT.NOW,
    type: TYPE.SIMPLE,
    vehiculeCategory: VEHICULE_CATEGORY.AMBULANCE,
  });

  const titleStyle = {
    fontFamily: "lato", // Remplacez "Arial" par la police que vous souhaitez utiliser
    fontSize: "14px", // Remplacez "16px" par la taille de police que vous souhaitez utiliser
    fontWeight: "600",
    color: "white", // Remplacez "bold" par le poids de police que vous souhaitez utiliser
  };

  const [extractedType, setextractedType] = useState(selectedFile);
  const { startAt, type } = state;

  const [open, setOpen] = useState(false);

  const [showBox, setShowBox] = useState(false);

  const [dateSelectionnee, setDateSelectionnee] = useState<any>();

  const handleDateCalendarChange = (nouvelleDate: any) => {
    setDateSelectionnee(nouvelleDate);
  };
  const updateField = (key: string, value: string) => {
    setState((current) => ({ ...current, [key]: value }));

    //pour vehicle catégroie
    if (value == "VSL") {
      setVehicle("VSL");
    }
    if (value == "AMBULANCE") {
      setVehicle("AMBULANCE");
    }
    //pour depart maintenant ou après
    if (value == "now") {
      setDemarrer("Maintenant");
    }
    if (value == "after") {
      setDemarrer("Plus tard");
    }
    if (value == "ar") {
      setTransportType(value);
    }
    if (value == "serie") {
      setTransportType(value);
    }
    if (value == "simple") {
      setTransportType(value);
    }
  };

  const handleInputChange = (event: any) => {
    setInputValue(event.target.value);
  };
  const handleNumberChange = (event: any) => {
    setphoneNumber(event.target.value);
  };
  const handlenameChange = (event: any) => {
    setName(event.target.value);
  };
  const handleLastNameChange = (event: any) => {
    setLastName(event.target.value);
  };
  function isValidPhoneNumber(phoneNumber: string): boolean {
    // Vérifier si le numéro de téléphone est un vrai numéro de téléphone valide
    // Vous pouvez utiliser une bibliothèque comme libphonenumber pour une validation plus robuste
    // Ici, nous allons simplement vérifier si le numéro contient uniquement des chiffres et a une longueur d'au moins 6 chiffres.
    return /^\d{6,}$/.test(phoneNumber);
  }
  function checkHasError() {
    if (
      name.trim() === "" ||
      lastname.trim() === "" ||
      (phoneNumber.trim() !== "" && !isValidPhoneNumber(phoneNumber)) ||
      typeof departureAdress?.latitude !== "number" ||
      typeof departureAdress?.longitude !== "number" ||
      typeof arrived?.latitude !== "number" ||
      typeof arrived?.longitude !== "number"
      // !selectedFile
    )
      return true;
    return false;
  }
  const isNow = (value: any) => {
    if (value === "now") {
      return true;
    } else {
      return false;
    }
  };
  const isRoundTrip = (value: any) => {
    if (value === "ar") {
      return true;
    } else {
      return false;
    }
  };

  const handleLocalisation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const geocoder = new window.google.maps.Geocoder();

          geocoder.geocode(
            { location: { lat: latitude, lng: longitude } },
            (results, status) => {
              if (status === "OK" && results && results.length > 0) {
                const address = results[0].formatted_address;

                setDepartureAdress({
                  latitude: latitude,
                  longitude: longitude,
                  address: address,
                });
                setIsFillDeparture(true);
              } else {
                console.error(
                  "Geocode was not successful for the following reason:",
                  status
                );
              }
            }
          );
        },
        (error) => {
          console.error("Error getting user location:", error);
        }
      );
    }
  };

  const schema = Yup.object().shape({
    name: Yup.string().required("Le prénom est requis."),
    lastname: Yup.string().required("Le nom de famille est requis."),
    demarrer: Yup.string().required("La valeur de démarrage est requise."),
    phoneNumber: Yup.string()
      .required("Le numéro de téléphone est requis.")
      .matches(
        /^[0-9]+$/,
        "Le numéro de téléphone doit contenir uniquement des chiffres."
      ),
  });
  // formater la date reçue
  const formatDataTime = new Date(dateSelectionnee);
  const hours = formatDataTime.getUTCHours() + 2;
  const minutes = formatDataTime.getUTCMinutes();

  const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}`;

  function formatDate(dateString: string): string {
    // Créez un objet Date à partir de la chaîne de date fournie
    const date = new Date(dateString);

    // Récupérez les informations de date
    const day = date.getDate();
    const month = date.getMonth() + 1; // Les mois commencent à partir de 0, donc ajoutez 1
    const year = date.getFullYear() % 100; // Obtenez les deux derniers chiffres de l'année

    // Formatez la date dans le format DD/MM/YY
    const formattedDate = `${day.toString().padStart(2, "0")}/${month
      .toString()
      .padStart(2, "0")}/${year.toString().padStart(2, "0")}`;

    return formattedDate;
  }
  function isDateTodayOrLater(
    dateString: string,
    verifiedHours: number = 24
  ): boolean {
    const date = new Date(dateString);
    const now = new Date();

    // Comparez les années, les mois et les jours pour vérifier si c'est aujourd'hui
    const isToday =
      date.getDate() === now.getDate() &&
      date.getMonth() === now.getMonth() &&
      date.getFullYear() === now.getFullYear();
    const hoursDifference = (date.getTime() - now.getTime()) / (1000 * 60 * 60);

    if (isToday && hoursDifference < verifiedHours) {
      return true; // C'est aujourd'hui
    } else {
      // Comparez les heures pour vérifier si c'est supérieur à l'heure actuelle + 4 heures

      return false;
    }
  }

  const callbackCreateRideRequest = (path: string) => {
    const dataRide = {
      patient: {
        firstname: name,
        lastname: lastname,
        phone: phoneNumber,
      },
      file: {
        path,
        name: selectedFile.name,
        size: selectedFile.size,
        extension: extractedType,
      },
      ride: {
        startNow: isNow(startAt),
        datetime: isNow(startAt)
          ? new Date().toISOString()
          : dateSelectionnee.toISOString(),
        isRoundTrip: isRoundTrip(type),
        vehicleCategory: state.vehiculeCategory,
      },
      pickup: {
        // @ts-ignore
        latitude: departureAdress.latitude,
        // @ts-ignore
        longitude: departureAdress.longitude,
        // @ts-ignore
        address: departureAdress.address,
      },

      dropoff: {
        // @ts-ignore
        latitude: arrived.latitude,
        // @ts-ignore
        longitude: arrived.longitude,
        // @ts-ignore
        address: arrived.address,
      },
    };
    const callback = (token: string) => {
      let prefix = "Attribution";
      if (
        dataRide.ride.startNow === false &&
        !isDateTodayOrLater(dateSelectionnee, MAX_HOURS_TO_SHOW_RIDE_TRACKING)
      )
        prefix = "After";
      navigate(`/${prefix}?token=${token}`);
      setIsInputEmpty(false);
    };
    dispatch(createRideRequest({ ride: dataRide, callback }));
  };

  const handleButtonClick = () => {
    // verifv form
    if (checkHasError()) return setIsInputEmpty(true);

    // ^pour la demo
    // return callbackCreateRideRequest();

    // get id unique between 5 characters
    const id = Math.random().toString(36).substr(2, 5);
    // concat with datetime milliseconds
    const filename = `${Date.now()}-${id}`;
    const path = "patient-ordonance/" + filename; // + ".xlsx";

    // demande au back
    
    dispatch(
      putPresignedUrl({
        path: [path],
        // le back renvoie une url sécurisé
        callback: (presignedsUrls) => {
          // pas recu le presigned
          // if (!presignedsUrls) return;
          uploadFile({
            file: selectedFile,
            url: presignedsUrls,
            // qd tout est bon créer le transport
            callback: () => callbackCreateRideRequest(path),
          });
        },
      })
    );
  };

  const inputFileRef = useRef<HTMLInputElement | null>(null); // Specify the correct type

  const handleImportDocument = () => {
    // Trigger the file selection when the user clicks on the box
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  };

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const handleFileChange = (event: any) => {
    // Handle the selected file here
    const selectedFile = event.target.files[0];

    setextractedType(selectedFile.type.split("/")[1]);
    setSelectedFile(selectedFile);
    // You can perform additional actions with the selected file, such as uploading it to a server, etc.

    setShowSuccessMessage(true);
  };
  const [openModal, setOpenModal] = useState(false);

  const handleModalOpen = () => {
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };
  function getDocumentLink(selectedFile: any) {
    const fileURL = URL.createObjectURL(selectedFile);
    return fileURL;
  }

  const isValidForm = !checkHasError();

  return (
    <Box
      display="flex"
      height="100vh"
      flexDirection={"column"}
      padding="0px 20px"
      flexGrow={1}
    >
      <Loading loading={loading} />
      {/* <QRCodeComponent /> */}
      {/* Logo */}
      <Box textAlign={"center"} paddingTop={"30px"}>
        <Icon.NewRideIcon.LogoResaam />
      </Box>

      {/* TITLE */}
      <Box
        color="primary.main"
        sx={{
          fontSize: {
            xs: "24px",
            sm: "28px",
            md: "2.5rem",
            lg: "2.5rem",
            xl: "2.5rem",
          },

          display: {
            md: "flex",
            lg: "flex",
            xl: "flex",
          },
          alignItems: {
            md: "center",
            lg: "center",
            xl: "center",
          },
          justifyContent: {
            md: "center",
            lg: "center",
            xl: "center",
          },
        }}
        fontSize={"2.5rem"}
        fontFamily="Lato"
        fontWeight={900}
        padding={"20px 0px"}
      >
        {t("title.new-ride")}
      </Box>
      {/* Infos générales */}
      <Box display={"flex"} gap="5px">
        <Box
          fontFamily={"lato"}
          fontSize={"16px"}
          color="#032A47"
          fontWeight={900}
        >
          Informations générales
        </Box>
        <Box display={"flex"}>
          <Box color="#CE5347">*</Box>
          <Box fontSize={"12px"} display="flex" alignItems={"center"}>
            Champs obligatoires
          </Box>
        </Box>
      </Box>

      <Box
        marginTop={"40px"}
        border={1}
        borderColor="rgba(0,0,0,.125)"
        borderRadius={"25px"}
        bgcolor={"white"}
      >
        {/* FORM */}
        <Box
          padding="15px 15px 40px 15px"
          display={"flex"}
          flexDirection="column"
          sx={{
            gap: {
              xs: "40px",
              sm: "30px",
              md: "37px",
              lg: "37px",
              xl: "38px",
            },
            marginTop: {
              sm: "40px",
              md: "60px",
              lg: "60px",
              xl: "60px",
            },
          }}
        >
          {/* ERROR */}
          <Box
            fontSize={"16px"}
            color="red"
            display="flex"
            alignItems={"center"}
            justifyContent={"center"}
          >
            {error}
          </Box>

          {/* START AT */}
          <Box display={"flex"} flexDirection={"column"} gap="15px">
            <Box display="flex">
              <Box fontSize={"16px"}>1/</Box>
              <Box
                fontSize={"14px"}
                display="flex"
                alignItems={"center"}
                marginRight={"5px"}
              >
                6
              </Box>
              <Box>La date</Box>
              <Box color="red">*</Box>
            </Box>
            <Box
              display={"flex"}
              flexGrow={1}
              sx={{
                flexDirection: {
                  xs: "column",
                  sm: "column",
                  md: "row",
                  lg: "row",
                  xl: "row",
                },

                gap: {
                  xs: "10px",
                },
              }}
            >
              <Box
                display="flex"
                flexDirection={"column"}
                bgcolor={"#F0F0F0"}
                sx={{
                  flexDirection: {
                    xs: "column",
                    sm: "column",
                    md: "column",
                    lg: "column",
                    xl: "column",
                  },
                  flexGrow: {
                    sm: 1,
                    md: 1,
                    lg: 1,
                    xl: 1,
                  },
                }}
                padding="5px 10px"
                borderRadius={"4px"}
                gap="5px"
              >
                <FormTabItem
                  label="Maintenant"
                  isActive={startAt === START_AT.NOW}
                  onClick={() => updateField("startAt", START_AT.NOW)}
                />
                <FormTabItem
                  label="Plus tard"
                  isActive={startAt === START_AT.AFTER}
                  onClick={() => updateField("startAt", START_AT.AFTER)}
                />
              </Box>

              <Box
                width="33.333%"
                sx={{
                  width: {
                    xs: "100%",
                    sm: "33.333%",
                    md: "33.333%",
                    lg: "33.333%",
                    xl: "33.333%",
                  },
                }}
              >
                {startAt === START_AT.AFTER ? (
                  <Box
                    paddingLeft={"10px"}
                    gap="10px"
                    display="flex"
                    textAlign={"center"}
                   
                  >
                    <Box
                      display="flex"
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <Icon.NewRideIcon.calendarIcon />
                    </Box>
                    <Box display={"flex"} flexGrow={1}>
                      {" "}
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["DateTimePicker"]}>
                          <Box display="flex" flexGrow={1}>
                            <MobileDateTimePicker
                              views={[
                                "year",
                                "month",
                                "day",
                                "hours",
                                "minutes",
                              ]}
                              slotProps={{ textField: { size: "small" } }}
                              value={dateSelectionnee}
                              onChange={handleDateCalendarChange}
                            />
                          </Box>
                        </DemoContainer>
                      </LocalizationProvider>
                    </Box>
                  </Box>
                ) : null}
              </Box>
            </Box>
          </Box>

          {/* TRANSPORT TYPE */}

          <Box display="flex" flexDirection={"column"} gap="15px">
            <Box display="flex">
              <Box fontSize={"16px"}>2/</Box>
              <Box
                fontSize={"14px"}
                display="flex"
                alignItems={"center"}
                marginRight={"5px"}
              >
                6
              </Box>
              <Box>Le trajet</Box>
              <Box color="red">*</Box>
            </Box>
            <Box
              flexGrow={1}
              display={"flex"}
              sx={{
                flexDirection: {
                  xs: "column",
                  sm: "column",
                  md: "row",
                  lg: "row",
                  xl: "row",
                },

                gap: {
                  xs: "10px",
                },
              }}
            >
              <Box
                display="flex"
                flexDirection={"column"}
                bgcolor={"#F0F0F0"}
                sx={{
                  
                  flexGrow: {
                    sm: 1,
                  },
                }}
                padding="5px 10px"
                borderRadius={"4px"}
                gap="5px"
              >
                {/* Simple */}

                <FormTabItem
                  label="Simple"
                  isActive={state.type === TYPE.SIMPLE}
                  onClick={() => updateField("type", TYPE.SIMPLE)}
                />
                {/* Aller/ Retour */}

                {/* Série */}
                <FormTabItem
                  label="Aller/Retour"
                  isActive={state.type === TYPE.AR}
                  onClick={() => updateField("type", TYPE.AR)}
                />

                <FormTabItem
                  label="Série"
                  isActive={state.type === TYPE.SERIE}
                  onClick={() => updateField("type", TYPE.SERIE)}
                />
                {/* Nb Soins */}
              </Box>
              <Box
                sx={{
                  width: {
                    xs: "110%",
                    sm: "33.333%",
                    md: "33.333%",
                    lg: "33.333%",
                    xl: "33.333%",
                  },
                }}
                width={"33.3333%"}
              >
                {state.type == TYPE.SERIE ? (
                  <Box
                    sx={{
                      height: {
                        sm: "41px",
                        md: "41px",
                        lg: "41px",
                        xl: "41px",
                      },
                    }}
                    height="41px"
                    display="flex"
                    flexDirection={"row"}
                    alignItems={"center"}
                  >
                    <Box
                      sx={{
                        padding: {
                          xs: "0px 5px ",
                          sm: "0px 12px",
                          md: "0px 12px",
                          lg: "0px 12px",
                          xl: "0px 12px",
                        },
                      }}
                      padding="0px 12px"
                      width="50%"
                      fontFamily={"lato"}
                      fontSize="15px"
                      fontWeight={400}
                      color="grey.400"
                      alignItems={"center"}
                    >
                      Nombres de soins :
                    </Box>
                    <Box width="50%" display="flex" marginRight={"10px"}>
                      <Box
                        border={1}
                        borderColor={isInputEmpty ? "red" : "grey.400"}
                        borderRadius={".25rem"}
                      >
                        <InputBase
                          error={isInputEmpty}
                          style={{
                            padding: "6px 12px",
                          }}
                          placeholder="0"
                          autoComplete="off"
                        ></InputBase>
                      </Box>
                    </Box>
                    <Box width="10%"></Box>
                  </Box>
                ) : (
                  <Box></Box>
                )}
              </Box>
            </Box>
          </Box>

          {/* Véhicule */}

          <Box display="flex" flexDirection={"column"} gap="15px">
            <Box display="flex">
              <Box fontSize={"16px"}>3/</Box>
              <Box
                fontSize={"14px"}
                display="flex"
                alignItems={"center"}
                marginRight={"5px"}
              >
                6
              </Box>
              <Box>Le véhicule</Box>
              <Box color="red">*</Box>
            </Box>
            <Box
              flexGrow={1}
              display={"flex"}
              sx={{
                flexDirection: {
                  xs: "column",
                  sm: "row",
                  md: "row",
                  lg: "row",
                  xl: "row",
                },

                gap: {
                  xs: "10px",
                },
              }}
            >
              <Box
                display="flex"
                flexDirection={"column"}
                bgcolor={"#F0F0F0"}
                sx={{
                  width: {
                    md: "66.6666%",
                    lg: "66.6666%",
                    xl: "66.6666%",
                  },
                  flexGrow: {
                    sm: 1,
                  },
                }}
                padding="5px 10px"
                borderRadius={"4px"}
                gap="5px"
              >
                {/* Simple */}

                <FormTabItem
                  label="Ambulance"
                  isActive={
                    state.vehiculeCategory === VEHICULE_CATEGORY.AMBULANCE
                  }
                  onClick={() =>
                    updateField("vehiculeCategory", VEHICULE_CATEGORY.AMBULANCE)
                  }
                />
                {/* Aller/ Retour */}

                {/* Série */}
                <FormTabItem
                  label="VSL"
                  isActive={state.vehiculeCategory === VEHICULE_CATEGORY.VSL}
                  onClick={() =>
                    updateField("vehiculeCategory", VEHICULE_CATEGORY.VSL)
                  }
                />

                <FormTabItem
                  label="Taxi"
                  isActive={state.vehiculeCategory === VEHICULE_CATEGORY.TAXI}
                  onClick={() =>
                    updateField("vehiculeCategory", VEHICULE_CATEGORY.TAXI)
                  }
                />
              </Box>
            </Box>
          </Box>

          {/* Prise en charge et destination */}
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap="15px"
            flexGrow={1}
          >
            <Box display={"flex"}>
              <Box fontSize={"16px"}>4/</Box>
              <Box
                fontSize={"14px"}
                display="flex"
                alignItems={"center"}
                marginRight={"5px"}
              >
                6
              </Box>
              <Box> La prise en charge et la destination</Box>
              <Box color="red">*</Box>
            </Box>
            <Box display={"flex"} flexGrow={1} gap="15px">
              <Box display={"flex"} flexGrow={2}>
                {/* <LocationSearchInput
                  value={departureAdress}
                  label="Lieu de prise en charge"
                  onChange={handleDepartureChange}
                  onSelect={handleSelectDeparture}
                /> */}
                <InputAddress
                  label={
                    isFillDeparture
                      ? `${departureAdress.address}`
                      : "Lieu de prise en charge"
                  }
                  onSelectAddress={setDepartureAdress}
                />
              </Box>
              <Box
                display="flex"
                width="25%"
                padding="7px 7px"
                gap="5px"
                bgcolor={"primary.main"}
                borderRadius={"5px"}
                alignItems={"center"}
                onClick={handleLocalisation}
                sx={{ cursor: "pointer" }}
                // maxHeight={"20px"}
              >
                <Box>
                  <Icon.NewRideIcon.mapIcon />
                </Box>
                <Box
                  fontSize={"12px"}
                  color="white"
                  display={"flex"}
                  fontWeight={900}
                >
                  Être localisé
                </Box>
              </Box>
            </Box>
            {/* {showMap && userLocation && (
                 
                 <GoogleMap
     mapContainerStyle={{ width: '100%', height: '400px' }}
     center={userLocation}
     zoom={15}
   ></GoogleMap>
        
 
 )} */}
            {/* destination */}
            <Box>
              {/* <Box
                display="flex"
                alignItems={"center"}
                border={1}
                borderColor={error === "" ? "grey.400" : "red"}
                height={"34px"}
                borderRadius="5px"
                padding="7px 7px"
              >
                <InputBase
                  style={{ width: "100%" }}
                  placeholder="Adresse de destination"
                  autoComplete="off"
                  onChange={handleArrivedChange}
                  value={arrived}
                ></InputBase>
              </Box> */}
              {/* <LocationSearchInput
                value={arrived}
                label="Adresse de destination"
                onChange={handleArrivedChange}
                onSelect={handleSelectArrived}
              /> */}
              <InputAddress
                onSelectAddress={setArrived}
                label="Adresse de destination"
              />
            </Box>
          </Box>

          {/* Infos Patient */}
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap="15px"
            flexGrow={1}
          >
            <Box display={"flex"}>
              <Box fontSize={"16px"}>5/</Box>
              <Box
                fontSize={"14px"}
                display="flex"
                alignItems={"center"}
                marginRight={"5px"}
              >
                6
              </Box>
              <Box>Coordonées</Box>
              <Box color="red">*</Box>
            </Box>
            <Box display={"flex"} gap="15px" flexGrow={1}>
              <Box display={"flex"} flexGrow={1}>
                <Box
                  display="flex"
                  alignItems={"center"}
                  border={1}
                  borderColor={isInputEmpty ? "red" : "grey.400"}
                  borderRadius="5px"
                  padding="7px 7px"
                  flexGrow={1}
                >
                  <InputBase
                    style={{ width: "100%" }}
                    placeholder="Nom"
                    autoComplete="off"
                    onChange={handlenameChange}
                    value={name}
                  ></InputBase>
                </Box>
              </Box>
              <Box display="flex" flexGrow={1}>
                <Box
                  display="flex"
                  alignItems={"center"}
                  border={1}
                  // borderColor={error === "" ? "grey.400" : "red"}
                  borderColor={isInputEmpty ? "red" : "grey.400"}
                  borderRadius="5px"
                  padding="7px 7px"
                  flexGrow={1}
                >
                  <InputBase
                    style={{ width: "100%" }}
                    placeholder="Prénom"
                    autoComplete="off"
                    onChange={handleLastNameChange}
                    value={lastname}
                  ></InputBase>
                </Box>
              </Box>
            </Box>
            {/* destination */}
            <Box>
              <Box
                display="flex"
                alignItems={"center"}
                border={1}
                borderColor={error === "" ? "grey.400" : "red"}
                // borderColor={isInputEmpty ? "red" : "grey.400"}
                height={"34px"}
                borderRadius="5px"
                padding="7px 7px"
              >
                <InputBase
                  style={{ width: "100%" }}
                  placeholder="Numéro de téléphone ( facultatif ) "
                  autoComplete="off"
                  onChange={handleNumberChange}
                  value={phoneNumber}
                ></InputBase>
                <Tooltip
                  enterTouchDelay={0}
                  title={
                    <span style={titleStyle}>
                      Obligatoire si le transport est plus tard.
                    </span>
                  }
                >
                  <Box>
                    <Icon.NewRideIcon.InformationIcon />
                  </Box>
                </Tooltip>
              </Box>
            </Box>
          </Box>

          {/* Ordonnance */}

          <Box display={"flex"} flexDirection={"column"} gap="30px">
            {/* label */}
            <Box display="flex">
              <Box fontSize={"16px"}>6/</Box>
              <Box
                fontSize={"14px"}
                display="flex"
                alignItems={"center"}
                marginRight={"5px"}
              >
                6
              </Box>
              <Box>Ordonance</Box>
              <Box color="red">*</Box>
            </Box>

            <Box
              display="flex"
              flexDirection={"column"}
              gap="12px"
              padding="0px 60px"
            >
              {/* <Box
                display={"flex"}
                padding="13px 7px"
                gap="5px"
                borderRadius={"4px"}
                bgcolor={"primary.main"}
                alignItems={"center"}
                onClick={handleModalOpen}
              >
                <Box>
                  <Icon.NewRideIcon.ScannerDocument />
                </Box>
                <Box fontSize={"14px"} color="white" fontWeight={900}>
                  Scanner un document
                </Box>
              </Box>
              <Modal open={openModal} onClose={handleModalClose}>
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    bgcolor: "white",
                    padding: "20px",
                    borderRadius: "4px",
                  }}
                >
                  //<Camera />
                  //<OCRScanner />
                  //<ScannerComponent />
                  <ImageScanner />
                </Box>
              </Modal> */}

             

              <Box
                display={"flex"}
                padding="13px 7px"
                gap="5px"
                border={1}
                borderColor={"primary.main"}
                borderRadius={"4px"}
                bgcolor={"white"}
                alignItems={"center"}
                onClick={handleImportDocument}
              >
                <input
                  type="file"
                  ref={inputFileRef}
                  style={{ display: "none" }} // Hide the input element
                  onChange={handleFileChange} // Call the function when a file is selected
                />

                <Box>
                  <Icon.Scanner.ImporterDocument />
                </Box>
                <Box fontSize={"14px"} color="primary.main" fontWeight={900}>
                  Importer un document
                </Box>
              </Box>
              {showSuccessMessage && (
                <Box
                  bgcolor="#4caf50"
                  textAlign={"center"}
                  padding="9px 0px"
                  borderRadius="4px"
                  color="white"
                  fontWeight={700}
                >
                  Document importé avec succès!
                </Box>
              )}
            </Box>
          </Box>

          <Box display="flex" padding="0px 80px">
            <Box
              onClick={isValidForm ? handleButtonClick : () => null}
              display={"flex"}
              borderRadius={"4px"}
              padding="20px 40px"
              flexGrow={1}
              alignItems={"center"}
              justifyContent={"center"}
              bgcolor={isValidForm ? "primary.main" : "#999C9F"}
              sx={{
                cursor: isValidForm ? "pointer" : "default",
              }}
            >
              <Box
                display={"flex"}
                flexGrow={1}
                alignItems={"center"}
                justifyContent={"center"}
                color="white"
                fontWeight={900}
              >
                VALIDER
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default NewRide;
