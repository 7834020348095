import { getBackendUrl } from '../globals/functions';


import { GraphQLClient } from "graphql-request";
// import Store  from "../store";

const graphQLClient = new GraphQLClient(getBackendUrl()||"", {
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    dataType: "json",
    // authorization: `Bearer ${token}`,
  },
});

export { graphQLClient };
