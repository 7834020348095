import { Box } from "@mui/material";
import { useState } from "react";

import GooglePlacesAutocomplete, {
  getLatLng,
  geocodeByPlaceId,
} from "react-google-places-autocomplete";

interface InputAddressProps {
  onSelectAddress: (address: {
    address: string;
    longitude: number;
    latitude: number;
  }) => void;
 label : string
}

const InputAddress: React.FC<InputAddressProps> = ({ onSelectAddress ,label}) => {
  const [value, setValue] = useState<any>("");

  const onInputChange = (prop: any) => {
    const { label, value } = prop;
    const { place_id, /* description */ } = value;
    setValue(prop);
    geocodeByPlaceId(place_id)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        onSelectAddress({
          address: label,
          longitude: lng,
          latitude: lat,
        });
      });
  };

  return (
    <Box flexGrow={1}>
      <GooglePlacesAutocomplete
        apiKey={"AIzaSyBmr4dyUxuEG52TSNG3xeP1CJZgjywR0Oo"}
        selectProps={{
          value,
          onChange: onInputChange,

          placeholder: label,
          noOptionsMessage: () => null,
          // blurInputOnSelect: true,
          components: {
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          },
        }}
        autocompletionRequest={{
          componentRestrictions: {
            country: ["fr"],
          },
        }}
        
        
      />
    </Box>
  );
};

export default InputAddress;
