import { saveAs } from "file-saver";

export function getBackendUrl() {
  let BACK_END: string | undefined = "";
  if (process.env.REACT_APP_STAGE === "dev")
    BACK_END = process.env.REACT_APP_BACK_END_URL_DEV;
  else if (process.env.REACT_APP_STAGE === "prod")
    BACK_END = process.env.REACT_APP_BACK_END_URL_PROD;
  else if (process.env.REACT_APP_STAGE === "demo")
    BACK_END = process.env.REACT_APP_BACK_END_URL_DEMO;
  else BACK_END = process.env.REACT_APP_BACK_END_URL;
  return BACK_END;
}

export function downloadFilesAsBlob(urls: string[], paths: string[]) {
  for (const urls3 of urls) {
    const file: any = paths.find((i: any) => urls3.includes(i.name));
    const extension = file?.extension || "png";
    fetch(urls3, { method: "GET" })
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        saveAs(blob, +file.name + "." + extension);
      })
      .catch((err) => {
        console.error("err: ", err);
      });
  }
}

// @ts-ignore
export function uploadFile({ url, file, callback }) {
  fetch(url, {
    method: "PUT",
    headers: { "Content-Type": file.type },
    body: file,
  })
    .then((res) => {
      if (typeof callback === "function") callback(res);
    })
    .catch((e) => {
      if (typeof callback === "function") callback(false);
    });
}
