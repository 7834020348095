import React from "react";
import { Box } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { Provider } from "react-redux";
import "../i18n";
import Layout from "../Layout";
import { colors } from "../globals/constantes";
import store from "../store";
import { getBackendUrl } from "../globals/functions";
import { HashRouter as Router } from "react-router-dom";

const theme = createTheme({
  palette: {
    primary: {
      main: colors.primary, // bleu ciel couleur service
    },
    secondary: {
      main: "rgb(19, 43, 68)", // bleu foncé
    },
    success: {
      main: "#ff0000",
    },
    grey: {
      "400": "rgb(153, 156, 159)", // gris texte
      "200": "#999C9F",
    },
  },
  typography: {
    fontFamily: "lato", // font de tout l'appli
  },
});

const client = new ApolloClient({
  uri: getBackendUrl(),
  cache: new InMemoryCache(),
});

const Boot: React.FC = () => {
  return (
    // API
    <ApolloProvider client={client}>
      {/* REDUX */}
      <Provider store={store}>
        {/* NAVIGATION */}
        <Router>
          {/* MATERIAL PERSONAL THEME */}
          <ThemeProvider theme={theme}>
            {/* APP */}
            <Box display={"flex"} flexGrow={1}>
              <Layout />
            </Box>
          </ThemeProvider>
        </Router>
      </Provider>
    </ApolloProvider>
  );
};

export default Boot;
