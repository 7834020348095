import { Box } from "@mui/material";
import { FC, useEffect } from "react";
import AttributionComponent from "../../components/AttributionComponent";

// import Notation from "../../components/Notation";

import { useAppDispatch, useAppSelector } from "..//..//store/hooks";
import { useLocation } from "react-router-dom"
import { getRideRequest } from "../../reducers/getRide";


/* Component React structure

1/ déclarations des variables
2/ hooks, useEffect
3/ function, callback declaration
4/ render
*/
const AssingementInProgress: FC = () => {
  
  const dispatch = useAppDispatch();
  const ride = useAppSelector((state) => state.getRide.ride);
  const search = useLocation().search;
  const token= new URLSearchParams(search).get("token");
  // Permet de récupéerr le lien
  // const [intervalId, setIntervalId] = useState<any>();
  // fonction qui pemret d'extraire que le token de l'url

  

  useEffect(() => {
    
    const fetchRideRequest = () => {
      dispatch(getRideRequest(token || ""));
    };

   // permet de faire la requete api tout les dix secondes donc met à jour les données notamment les status
    fetchRideRequest();
    window.setInterval(fetchRideRequest, 10000);
  }, []);

  /**
   * name :  rideNumber
   * priseEnCharge :  dateTime
   * lieu : pickup
   * + dropoff ( on est chez le patient, prise enc harge du patient, transport terminé )
   * status : intérieur : onway ,...
   * liste des status :
   *  pending  (attribué à une entreprise  mais l'entreprise n'a pas encore commecé le transport)
   *  incoming
   *  onway
   *  waiting
   *  onboard
   *  arrived
   *  completed
   *  canceled
   */
  // Ajouter status waiting
  // status = arrived gérer 

  if (!ride) return <Box>Un problème est survenu lors de la création du transport</Box> 
  // const statuses = ["completed", "arrived"];

  // let isIncluded = ride.status && statuses.includes(ride.status);
  return (
    <Box
      display="flex"
      flexDirection={"column"}
      flexGrow={1}
      height={"100vh"}
      gap="20px"
    >
      <Box display={"flex"} flexGrow={1}>
        <AttributionComponent ride={ride} />
      </Box>
      {/* {!isIncluded ? (
      ) : (
        <Box height={"100%"} bgcolor={"white"}>
          <Notation />
        </Box>
      )} */}
    </Box>
  );
};
export default AssingementInProgress;
