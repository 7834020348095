import React, { useState } from 'react';
import { createWorker } from 'tesseract.js';
import { useDropzone } from 'react-dropzone';
import { Document, Page } from 'react-pdf';
import { Box } from '@mui/material';
import Icon from "../assets";


const OCRScanner: React.FC = () => {
  const [recognizedText, setRecognizedText] = useState('');
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const [numPages, setNumPages] = useState(0);
  const [uploadedDocuments, setUploadedDocuments] = useState<any[]>([]);

  const onDrop = async (acceptedFiles: File[]) => {
    const worker = await createWorker();
    await worker.load();
    await worker.loadLanguage('eng');
    await worker.initialize('eng');

    const file = acceptedFiles[0];
    const { data } = await worker.recognize(file);
    setRecognizedText(data.text);

    const pdfUrl = URL.createObjectURL(file);
    setPdfUrl(pdfUrl);

    await worker.terminate();
       // Ajouter le document téléchargé à la liste des documents
       const uploadedDocument = {
        name: file.name,
        size: file.size,
        url: pdfUrl,
        id: uploadedDocuments.length + 1,
      };
      setUploadedDocuments([...uploadedDocuments, uploadedDocument]);
  };
  

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ 
    onDrop,
    accept : {
    'image/*': ['jpg', 'jpeg', 'png', 'gif'],
    '.pdf': ['pdf'],
    }
  });

  const handlePdfLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const [isComponentVisible, setComponentVisible] = useState(false);

  const handleFile = () => {
    // Logique pour le traitement du fichier
    // Ici, nous allons simplement basculer la visibilité du composant
    setComponentVisible(!isComponentVisible);
  };
  function formatFileSize(size: number) {
    if (size === 0) return '0 Bytes';
    const k = 1024;

    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(size) / Math.log(k));
    return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  }

  return (
    <div>
        <Box
         onClick={handleFile}
              
              style={{
                display: "flex",
                backgroundColor: "#0d6efd",
                width: "40px",
                height: "38px",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: ".25rem",
                cursor: "pointer",
              }}
            >
              {" "}
              <Icon.GestionFichierIcon.GestionAjouterIcon />
            </Box>
            {isComponentVisible && 
             <div
             {...getRootProps()}
             className={`dropzone ${isDragActive ? 'active' : ''}`}
             style={{
               border: '2px dashed #ccc',
               padding: '20px',
               textAlign: 'center',
               cursor: 'pointer',
               width:'300px',
               height:'60px'
             }}
           >
             <input {...getInputProps()} />
             {isDragActive ? (
               <p>Relâchez le fichier ici...</p>
             ) : (
               <p>Glissez-déposez un fichier ou cliquez pour sélectionner un fichier à scanner</p>
             )}
           </div>}
           {uploadedDocuments.map((document, index) => (
        <div key={index}>
          {document.id}
          <a href={document.url} target="_blank" rel="noopener noreferrer">
            {document.name}
          </a>
          <span> - {formatFileSize(document.size)}</span>
        </div>
      ))}
    
     
      {/* {recognizedText && <div>{recognizedText}</div>}
      {pdfUrl && (
        <div style={{ marginTop: '20px' }}>
          <Document file={pdfUrl} onLoadSuccess={handlePdfLoadSuccess}>
            {Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
          </Document>
        </div>
      )} */}
      {/* {recognizedText && (
        <div>
          <h2>Contenu du Document</h2>
          <pre>{recognizedText}</pre>
        </div>
      )} */}
    </div>
  );
};

export default OCRScanner;
