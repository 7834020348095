import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

interface Props {
    loading: boolean;
}

const Loading: React.FC<Props> = ({ loading }) => {
    if (!loading) return <></>;
    return (
    <Box
      position="fixed"
      top={0}
      bottom={0}
      right={0}
      left={0}
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexGrow={1}
      bgcolor="rgba(0,0,0, 0.5)"
      
    >
      <CircularProgress color="primary" size={100} />
    </Box>
    );
};

export default Loading;
