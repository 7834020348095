import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface IminioPayload {
  path: string[];
  callback: (props:any) => void;
}
const initialState = {};

export const minioSlice = createSlice({
  name: "minio",
  initialState,
  reducers: {
    getPresignedUrl: (state, action: PayloadAction<IminioPayload>) => {},
    putPresignedUrl: (state, action: PayloadAction<IminioPayload>) => {},
  },
});

// Action creators are generated for each case reducer function
export const { getPresignedUrl, putPresignedUrl } = minioSlice.actions;

export default minioSlice.reducer;
