import { FC } from "react";
import { Routes } from "react-router-dom";
import { getRoutes } from "../routes";
import { Box } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";

import React from "react";

const MOBILE_WIDTH = 1100;

function useWindowSize() {
  const [size, setSize] = useState([window.innerWidth, window.innerHeight]);
  useEffect(() => {
    const handleResize = () => setSize([window.innerWidth, window.innerHeight]);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return size;
}

const App: FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const pathSegments = location.pathname.split("/");
  const pageName = pathSegments[pathSegments.length - 1];
  const [width] = useWindowSize();



  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        height: "100vh",
        bgColor: "#F9F8F8",
      }}
    >
      <Box display="flex" flexDirection="column" flexGrow={1}>
       
        <Box
          display="flex"
          flexGrow={1}
        >
          <Routes>{getRoutes("/private")}</Routes>
        </Box>
      </Box>
    </Box>
  );
};
export default App;
