import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface CreateRideState {
  loading: boolean;
  error: string;
  token: string;
}

interface NewRidePayload {
  ride: {
    startNow: boolean;
    datetime: string;
    isRoundTrip: boolean;
    vehicleCategory: string;
  };

  pickup: {
    latitude: number;
    longitude: number;
    address: string;
  };
  dropoff: {
    latitude: number;
    longitude: number;
    address: string;
  };

  patient: {
    firstname: string;
    lastname: string;
    phone: string;
  };
  file: {
    path: string;
  };
}
export interface CreateRideRequestPayload {
  //Queryy
  ride: NewRidePayload,
  callback: (token: string) => void,
}

const initialState: CreateRideState = {
  loading: false,
  error: "",
  token: "",
};

export const createRideSlice = createSlice({
  name: "createRide",
  initialState,
  reducers: {
    createRideRequest: (
      state,
      action: PayloadAction<CreateRideRequestPayload>,
    ) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes.
      // Also, no return statement is required from these functions.
      state.loading = true;
      state.error = "";
    },
    createRideSuccess: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.token = action.payload;
    },
    createRideFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { createRideRequest, createRideSuccess, createRideFailure } =
  createRideSlice.actions;

export default createRideSlice.reducer;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value
