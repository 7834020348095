import { AttributionComponentType, RideType } from "../globals/type";
import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";

import Icon from "..//assets";
import MapsGoogle from "./MapsGoogle";
import AMBULANCEHORIZONTAL from "..//assets/vehicleCategory/front/ambulance.svg";
import VSLHORIZONTAL from "..//assets/vehicleCategory/front/vsl.svg";
import TAXIHORIZONTAL from "..//assets/vehicleCategory/front/taxi.svg";

import { DriverLocationMapModalProps } from "./MapsGoogle";
import { useState } from "react";
import moment from 'moment';

interface IAttributionComponent {
  ride: RideType;
  coordinates?: { driverId: any; latitude: number; longitude: number }[];
}

const TRANSLATION = {
  title_status_onway: "Prise en charge à",
  title_status_onboard: "Arrivé prévue à",
};
const STATUS_TRADUCTION = {
  incoming: "Nous cherchons un véhicule",
  pending: "Transport attribué",
  pendingWithVehicle: "Véhicule attribué",
  waiting: "Votre transport vous attend",
  onway: "Véhicule en chemin",
  onboard: "Transport en cours",
  arrived: "",
  completed: "",
  unavailable: "Nous cherchons un véhicule",
  canceled: " Transport Annulé",
};
// arrived et completed afficher la notation mais si l'utiisateur ne veut pas noter ou a noté afficher transport terminé

const vehcileCategoryIcon = {
  AMBULANCE : AMBULANCEHORIZONTAL,
  VSL : VSLHORIZONTAL,
  TAXI : TAXIHORIZONTAL,
}

const AttributionComponent: React.FC<IAttributionComponent> = ({ ride }) => {
  const formattedTime = moment().add("seconds", ride.waitingTime?.duration_in_traffic||0).format("HH[h]mm")

  const statuses = ["incoming"];

  const statutHour = ["pending", "pendingWithVehicle", "waiting", "onway"];
  let isIncludedHour = ride.status && statutHour.includes(ride.status);

  let isIncluded = ride.status && statuses.includes(ride.status);
  let coordinate: {
    driverId: string;
    latitude: number;
    longitude: number;
  };
  const coordinates: {
    driverId: number;
    latitude: number;
    longitude: number;
  }[] = [
    
    {
      //@ts-ignore
      driverId: ride.transporter?.driver?.coords.heading,
      //@ts-ignore
      latitude: ride.transporter?.driver?.coords.latitude,
      //@ts-ignore
      longitude: ride.transporter?.driver?.coords.longitude,
    },

    // Add more coordinates as needed
  ];
  const [duration, setDuration] = useState<string | null>(null);

  // Function to handle the duration change callback
  const handleDurationChange = (newDuration: string) => {
    setDuration(newDuration);
  };

  return (
    <Box display="flex" flexDirection={"column"} gap="20px">
      <Box height="60%">
        <MapsGoogle ride={ride} coordinates={coordinates} />
      </Box>

      <Box
        display="flex"
        flexGrow={1}
        flexDirection={"column"}
        gap="20px"
        padding="0px 30px"
      >
        {isIncluded ? (
          <Box display="flex" flexDirection={"column"} gap="25px">
            <Box
              textAlign={"center"}
              color="#032A47"
              fontSize={"24px"}
              fontWeight={900}
            >
              {STATUS_TRADUCTION[ride.status]}
            </Box>
            <Box display="flex">
              <Box
                width="60%"
                display="flex"
                flexDirection={"column"}
                gap="10px"
              >
                <Box display={"flex"} flexDirection={"column"} gap="8px">
                  <Box color="#8A8A8D">Numéro de réservation</Box>
                  <Box fontSize={"18px"} color={"#032A47"}>
                    {ride.rideNumber}
                  </Box>
                </Box>
                <Box display={"flex"} flexDirection={"column"} gap="8px">
                  <Box color="#8A8A8D" fontSize={"12px"}>
                    Heure de prise en charge
                  </Box>
                  <Box color={"#032A47"} fontSize={"12px"}>
                    {formattedTime}
                  </Box>
                </Box>
                <Box display={"flex"} flexDirection={"column"} gap="8px">
                  <Box color={"#8A8A8D"} fontSize={"12px"}>
                    Lieu de prise en charge
                  </Box>
                  <Box color="032A47" fontSize={"12px"}>
                    {ride.pickup.address}
                  </Box>
                </Box>
              </Box>
              <Box
                width="40%"
                display="flex"
                alignItems={"center"}
                justifyContent={"center"}
              >
                {ride.status == "incoming" ? (
                  <Box
                    display="flex"
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <CircularProgress color="primary" />
                  </Box>
                ) : null}

                {ride.status == "pending" && (
                  <Box
                    display="flex"
                    alignItems={"center"}
                    justifyContent={"center"}
                    gap="20px"
                    flexDirection={"column"}
                  >
                    <Box display="flex" gap="10px">
                      <Box>
                        <Icon.AssignementIcon.LogoEntrepriseAmbulance />
                      </Box>
                      <Box>
                        <Box color="#999C9F" fontSize={"12px"}>
                          Retrouvez l'entreprise
                        </Box>

                        <Box fontWeight={600} fontSize={"12px"}>
                          {ride.transporter?.company.name}
                        </Box>
                      </Box>
                    </Box>

                    {ride.transporter?.vehicle && (
                      <Box display="flex" gap="10px">
                        <Box>
                         
                          <img src={vehcileCategoryIcon[ride.vehicleCategory]} alt="Logo" />
                        </Box>
                        <Box
                          display="flex"
                          flexDirection={"column"}
                          gap="5px"
                          alignItems={"center"}
                          justifyContent={"center"}
                        >
                          <Box color={"#9273FF"}>
                            {ride.transporter?.vehicle?.plate.toUpperCase()}
                          </Box>
                          <Box>{ride.transporter?.vehicle?.model}</Box>
                          <Box>{ride.transporter?.vehicle?.color}</Box>
                        </Box>
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        ) : (
          <Box display="flex" flexDirection={"column"} gap="10px">
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap="10px"
              textAlign={"center"}
            >
              <Box color="#032A47" fontSize={"24px"} fontWeight={900}>
                {STATUS_TRADUCTION[ride.status]}
              </Box>
              {isIncludedHour && (
                <Box color="#8A8A8D" fontSize={"12px"}>
                  Prise en charge prévue à {formattedTime}
                </Box>
              )}
              {ride.status == "onboard" && (
                <Box color="#8A8A8D" fontSize={"12px"}>
                  Arrivée prévu à {formattedTime}
                </Box>
              )}
              {/* {
                ride.status==="pending" && (
                  <Box display={"flex"} gap="8px" justifyContent={'center'}>
                  <Box color="#8A8A8D" fontSize={"12px"}>
                    Heure de prise en charge
                  </Box>
                  <Box color={"#032A47"} fontSize={"12px"}>
                    {formattedTime}
                  </Box>
                </Box>
                )
              } */}
            </Box>

            <Box
              display="flex"
              borderTop={1}
              borderColor={"#E5E5E5"}
              padding="20px 0px"
              // sx={{
              //   marginTop:
              //     ride.status === "pending" || "waiting" ? "10px" : "0px",
              // }}
            >
              <Box display="flex" gap="10px" width={"50%"}>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Icon.AssignementIcon.LogoEntrepriseAmbulance />
                </Box>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  fontSize={"12px"}
                >
                  {ride.transporter?.company.name}
                </Box>
              </Box>

              <Box
                display="flex"
                gap="10px"
                justifyContent={"center"}
                alignItems={"center"}
                width="50%"
              >
                <Box>
                
                  <img src={vehcileCategoryIcon[ride.vehicleCategory]} alt="Logo" />
               
                </Box>
                <Box display="flex" flexDirection={"column"}>
                  <Box color={"#9273FF"} fontSize={"12px"}>
                    {ride.transporter?.vehicle?.plate.toUpperCase()}
                  </Box>
                  <Box color={"#032A47"}>
                    {ride.transporter?.vehicle?.color}
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box
              display={"flex"}
              borderTop={1}
              borderColor={"#E5E5E5"}
              paddingTop="23px"
            >
              <Box
                width={"50%"}
                display="flex"
                flexDirection={"column"}
                gap="15px"
              >
                <Box display={"flex"} gap="5px" flexDirection={"column"}>
                  <Box color="#8A8A8D" fontSize={"14px"}>
                    Lieu de prise en charge
                  </Box>
                  <Box color="#032A47" fontSize={"12px"}>
                    {ride.pickup.address}
                  </Box>
                </Box>

                <Box display={"flex"} gap="5px" flexDirection={"column"}>
                  <Box color="#8A8A8D" fontSize={"14px"}>
                    Destination
                  </Box>
                  <Box color="#032A47" fontSize={"12px"}>
                    {ride.dropoff?.address}
                  </Box>
                </Box>
              </Box>

              <Box
                width={"50%"}
                display="flex"
                flexDirection={"column"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Box fontSize={"14px"} color="#8A8A8D">
                  Numéro de reservation
                </Box>
                <Box color={"#032A47"}>{ride.rideNumber}</Box>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default AttributionComponent;
