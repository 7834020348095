import { put, takeLatest } from "redux-saga/effects";
import {
  createRideRequest,
  createRideFailure,
  createRideSuccess,
  CreateRideRequestPayload,
} from "../reducers/createRide";
import { graphQLClient } from "../graphql";
import { PayloadAction } from "@reduxjs/toolkit";



function* createRideAsyncRequest(action: PayloadAction<CreateRideRequestPayload>) {

  const { file, patient,dropoff,pickup,ride  } = action.payload.ride;
  const { callback } = action.payload;
  
  try {
    const query = `
      mutation {
        PatientTrackingCreateNewRide(
          file: {
            path: "${file.path}",
          },
          ride: {
            startNow: ${ride.startNow},
            datetime: "${ride.datetime}",
            isRoundTrip: ${ride.isRoundTrip},
            vehicleCategory: ${ride.vehicleCategory},
            pickup: {
              latitude: ${pickup.latitude},
              longitude: ${pickup.longitude},
              address: "${pickup.address}"
            },
            dropoff: {
              latitude: ${dropoff.latitude},
              longitude: ${dropoff.longitude},
              address: "${dropoff.address}"
            }
            patient: {
              firstname: "${patient.firstname}",
              lastname: "${patient.lastname}",
              phone: "${patient.phone}"
            }
          }
        )
      }
    `;
    const { PatientTrackingCreateNewRide: token } = yield graphQLClient.request(query);
    yield put(createRideSuccess(token));
    callback(token);
  } catch (error: any) {
    yield put(
      createRideFailure(
        "Une erreur est survenue lors de la création du transport"
      )
    );
  }
}

export default [takeLatest(createRideRequest, createRideAsyncRequest)];
