import { put, takeLatest } from "redux-saga/effects";
import {
  getRideRequest,
  getRideFailure,
  getRideSuccess,
} from "..//reducers/getRide";
import { graphQLClient } from "../graphql";
import { PayloadAction } from "@reduxjs/toolkit";

function* getRideAsyncRequest(action: PayloadAction<string>) {
  try {
    const query = `
      query{
        PatientLoadRideForPatient(token: "${action.payload}")
      }
    `;
    
    const { PatientLoadRideForPatient } = yield graphQLClient.request(query);
    yield put(getRideSuccess(PatientLoadRideForPatient));
  } catch (error: any) {
    yield put(getRideFailure("Pas de transport disponible"));
  }
}

export default [takeLatest(getRideRequest, getRideAsyncRequest)];
