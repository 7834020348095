
import * as NewRideIcon from  "./NewRideIcon"
import * as GestionFichierIcon from './GestionFichierIcon'
import * as Scanner from './Scanner'

import * as AssignementIcon from './AssignementIcon'
export default {
  
    NewRideIcon,
    GestionFichierIcon,
    Scanner,
   
    AssignementIcon


    
};
