import React, { Component, useEffect } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  DirectionsRenderer,
} from "@react-google-maps/api";
import resaamVehicle from "..//assets//vehicleCategory/top/ambulance.svg";
import vslVehicle from "..//assets/vehicleCategory/top/vsl.svg";
import taxiVehcile from "..//assets/vehicleCategory/top/taxi.svg"
import LieuDarrivee from "..//assets/Lieu-darrivee.svg";
import LieuDePriseEnCharge from "..//assets/Lieu-de-prise-en-charge.svg";
import googleMapStyle from "..//assets/GoogleMapsStyle";

const ResaamVehicleIcon = {
  AMBULANCE: resaamVehicle,
  VSL: vslVehicle,
  TAXI : taxiVehcile,
};

// ResaamVehicleIcon[NewRide.vehicleType];

export interface DriverLocationMapModalProps {
  coordinates?: { driverId: number; latitude: number; longitude: number }[];
  ride: {
    status:
      | "incoming"
      | "pending"
      | "onway"
      | "onboard"
      | "arrived"
      | "completed"
      | "unavailable"
      | "waiting";
    pickup: { latitude: number; longitude: number };
    dropoff: { latitude: number; longitude: number };
    vehicleCategory: "AMBULANCE" | "VSL";
  };
}
function MapView(props: any) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GMAPS_KEY || "",
  });
  const [map, setMap] = React.useState(null);
  const [direction, setDirection] =
    React.useState<google.maps.DirectionsResult | null>(null);
  const [currentPosition, setCurrentPosition] =
    React.useState<google.maps.LatLng | null>(null);

  const onUnmount = React.useCallback(function callback(map: any) {
    setMap(null);
  }, []);

  const isVehiculeCategoryAmbulance = () => {
    if (props.vehicleCategory === "Ambumlance") {
      return true;
    }
    return false;
  };

  const fitBounds = () => {
    const markers = [];
    if (props.from) markers.push(props.from);
    else if (props.coords && props.coords.length > 1)
      // @ts-ignore

      props.coords.map((coord) => markers.push(coord));
    if (props.to) markers.push(props.to);

    const bounds = new window.google.maps.LatLngBounds();
    markers.forEach((position) => bounds.extend(position));

    const resaamVehicleMarker = markers.find(
      (marker) => marker.icon === resaamVehicle
    );
    if (resaamVehicleMarker) {
      bounds.extend(
        new window.google.maps.LatLng(
          resaamVehicleMarker.lat,
          resaamVehicleMarker.lng
        )
      );
    }
    // @ts-ignore

    map.fitBounds(bounds);
    // @ts-ignore
    map.setZoom(12);
  };
  const [duration, setDuration] = React.useState<number | null>();

  const onLoadMap = (map: any) => {
    setMap(map);
  };

  const getDirection = async () => {
    const directionService = new window.google.maps.DirectionsService();
    const direction = await directionService.route({
      origin: new window.google.maps.LatLng(props.from),
      destination: new window.google.maps.LatLng(props.to),
      travelMode: window.google.maps.TravelMode.DRIVING,
    });

    setDirection(direction);

    // récupère le watingTime cote front
    // if (direction && direction.routes && direction.routes.length > 0) {
    //   //@ts-ignore
    //   const durationInSeconds = direction.routes[0].legs[0].duration.value;
    //   const minutes = Math.floor(durationInSeconds / 60);
    //   setDuration(minutes);
    // dipsatch(updateWaitingTime(minutes))
    // }

    // Extract the steps of the route
    const steps = direction.routes[0].legs[0].steps;

    // Start moving the icon along the route
    let stepIndex = 0;
    const moveIconAlongRoute = () => {
      if (stepIndex < steps.length) {
        const nextStep = steps[stepIndex];
        const nextPosition = nextStep.start_location;
        setCurrentPosition(nextPosition);
        stepIndex++;
        setTimeout(moveIconAlongRoute, 1000); // Update the position every second
      }
    };
    moveIconAlongRoute();
  };

  useEffect(() => {
    if (map) {
      fitBounds();
      if (props.from && props.to) getDirection();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={{ height: "100%", width: "100vw" }}
      center={props.center}
      options={googleMapStyle}
      onLoad={onLoadMap}
      onUnmount={onUnmount}
      zoom={12}
    >
      {/* Child components, such as markers, info windows, etc. */}

      {
        // @ts-ignore
        props.coords &&
          props.coords.length > 1 &&
          props.coords.map((coord: any) => (
            <Marker
              key={coord.driverId}
              position={coord}
              label={coord.plate}
              icon={{
                url: coord.icon,
                labelOrigin: new window.google.maps.Point(40, 35),
              }}
            />
          ))
      }
      {props.from && props.coords.length === 1 && (
        <Marker
          position={props.from}
          label={props.from.plate}
          icon={{
            url: props.from.icon,
            labelOrigin: new window.google.maps.Point(40, 35),
            //anchor: new google.maps.Point(5, 58)
          }}
        />
      )}
      {props.to && (
        <Marker
          position={props.to}
          icon={{
            url: props.to.icon,
            //anchor: new google.maps.Point(5, 58)
          }}
        />
      )}
      {direction && (
        <DirectionsRenderer
          directions={direction}
          options={{
            markerOptions: { icon: " " },
            polylineOptions: {
              zIndex: 10,
              strokeColor: "#9273FF",
              strokeWeight: 5,
            },
          }}
        />
      )}
    </GoogleMap>
  ) : (
    <></>
  );
}
class DriverLocationMapModal extends Component<DriverLocationMapModalProps> {
  render() {
    // @ts-ignore
    const { coordinates = [], ride } = this.props;
    let latitude = 48.856614;
    let longitude = 2.3522219;
    if (coordinates.length > 0) {
      if (coordinates[0]?.latitude && coordinates[0]?.longitude) {
        latitude = coordinates[0].latitude;
        longitude = coordinates[0].longitude;
      }
    }
    let to = undefined;
    let from = undefined;
    if (
      (ride && ride.status === "incoming") ||
      (ride.status === "pending" &&
        ride.dropoff.longitude &&
        ride.dropoff.latitude)
    ) {
      from = {
        lat: ride.pickup.latitude,
        lng: ride.pickup.longitude,
        icon: LieuDePriseEnCharge,
      };
      to = {
        lat: ride.dropoff.latitude,
        lng: ride.dropoff.longitude,
        icon: LieuDarrivee,
      };
    } else if (ride && ride.status === "onway") {
      from = {
        lat: coordinates[0].latitude,
        lng: coordinates[0].longitude,
        icon: ResaamVehicleIcon[ride.vehicleCategory],
      };
      to = {
        lat: ride.pickup.latitude,
        lng: ride.pickup.longitude,
        icon: LieuDePriseEnCharge,
      };
    } else if (
      ride &&
      ["onboard", "waiting"].includes(ride.status) &&
      ride.dropoff &&
      ride.dropoff.longitude &&
      ride.dropoff.latitude
    ) {
      from = {
        lat: coordinates[0].latitude,
        lng: coordinates[0].longitude,
        icon: ResaamVehicleIcon[ride.vehicleCategory],
      };
      to = {
        lat: ride.dropoff.latitude,
        lng: ride.dropoff.longitude,
        icon: LieuDarrivee,
      };
    }

    return (
      <MapView
        // @ts-ignore
        coords={coordinates.map((coord) => ({
          ...coord,
          lng: coord.longitude,
          lat: coord.latitude,
          icon: ResaamVehicleIcon[ride.vehicleCategory],
        }))}
        center={{ lat: latitude, lng: longitude }}
        from={from}
        to={to}
      />
    );
  }
}
export default DriverLocationMapModal;
