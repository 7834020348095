
import { all } from "redux-saga/effects";
import CreateRide from "./createRide";
import getRide from "./getRide";
import minio from "./minio";
export default function* root(){
    yield all ([
        ...CreateRide,
        ...getRide,
        ...minio,
    ]);
}