import { BrowserRouter, Route, Link, useLocation } from "react-router-dom";
import { FC } from "react";
import { Box, Checkbox } from "@mui/material";

import SettingIcon from "..//..//assets";
import ProSanteConnect from "..//..//assets/prosanteconnect.png";

import { title } from "process";
import { useTranslation } from "react-i18next";

const SubTitle: FC<{ title: string }> = ({ title }) => <div>{title}</div>;

const Settings: FC = () => {
  const location = useLocation();
  const pathSegments = location.pathname.split("/");
  const pageName = pathSegments[pathSegments.length - 1];
  const { t } = useTranslation();

  function redirectToFranceSanteConnect() {
    window.location.replace('https://www.franceconnect.gouv.fr/');
  }
  return (
    <Box
      flexGrow={1}
      display={"flex"}
      flexDirection="column"
      gap="25px"
    >
      <Box
        fontWeight={900}
        fontSize={"40px"}
        fontFamily="lato"
        color="primary.main"
        
        
        lineHeight="1.2"
        padding="20px 12px 0px 12px"
        margin="0px 0px 8px"
        
      >
        {t(`title.${pageName}`)}
      </Box>
      {/* profil utilisateur */}
      <Box padding="0px 12px">
        <Box
          bgcolor={""}
          marginBottom={".5rem!important"}
          fontWeight={600}
          fontSize="24px"
          fontFamily={"lato"}
          color="primary.main"
     
        >
          Profil utilisateur
        </Box>
        <Box display="flex" flexDirection={"column"} gap="5px">
          <Box display={"flex"} flexDirection="row" gap="1rem!important">
            <Box fontWeight={600} fontFamily="lato" fontSize={"16px"}>
              Nom :
            </Box>
            <Box
              textTransform={"uppercase"}
              fontSize="16px"
              fontFamily={"lato"}
            >
              ANDRIANIRAISANTSOA
            </Box>
          </Box>
          <Box display={"flex"} flexDirection="row" gap="1rem!important">
            <Box fontWeight={600} fontFamily="lato" fontSize={"16px"}>
              Prénom :
            </Box>
            <Box fontSize="16px" fontFamily={"lato"}>
              Tefy
            </Box>
          </Box>
        </Box>
      </Box>
      {/* service */}
      <Box padding="0px 12px">
        <Box
          fontWeight={600}
          fontSize="24px"
          fontFamily={"lato"}
          color="primary.main"
          marginBottom={".5rem!important"}
        >
          Service
        </Box>
        <Box display="flex" flexDirection={"column"} gap="5px">
          <Box display={"flex"} flexDirection="row" gap="1rem!important">
            <Box fontWeight={600} fontFamily="lato" fontSize={"16px"}>
              Nom :
            </Box>
            <Box
              textTransform={"uppercase"}
              fontSize="16px"
              fontFamily={"lato"}
            >
              Nom service
            </Box>
          </Box>
          <Box display={"flex"} flexDirection="row" gap="1rem!important">
            <Box fontWeight={600} fontFamily="lato" fontSize={"16px"}>
              Adresse:
            </Box>
            <Box fontSize="16px" fontFamily={"lato"}>
              Station F
            </Box>
          </Box>
          <Box
            fontSize={"20px"}
            fontWeight={600}
            color="rgb(102, 102, 102)"
            margin="8px 0px 8px"
          >
            Contact
          </Box>
        </Box>

        <Box display="flex" flexDirection={"column"} gap="5px">
          <Box display={"flex"} flexDirection="row" gap="1rem!important">
            <Box fontWeight={600} fontFamily="lato" fontSize={"16px"}>
              Nom :
            </Box>
            <Box fontSize="16px" fontFamily={"lato"}>
              Resaam
            </Box>
          </Box>
          <Box display={"flex"} flexDirection="row" gap="1rem!important">
            <Box fontWeight={600} fontFamily="lato" fontSize={"16px"}>
              Prénom :
            </Box>
            <Box fontSize="16px" fontFamily={"lato"}>
              Fares
            </Box>
          </Box>
          <Box display={"flex"} flexDirection="row" gap="1rem!important">
            <Box fontWeight={600} fontFamily="lato" fontSize={"16px"}>
              Email:
            </Box>
            <Box fontSize="16px" fontFamily={"lato"}>
              <Link to={""} style={{ color: "#0d6efd" }}>
                tefymaherison19@gmail.com
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* Etablissement */}
      {/* <Box padding="0px 12px">
        <Box
          fontWeight={600}
          fontSize="24px"
          fontFamily={"lato"}
          color="primary.main"
          marginBottom={".5rem!important"}
        >
          Etablissement
        </Box>
        <Box display="flex" flexDirection={"column"} gap="5px">
          <Box display={"flex"} flexDirection="row" gap="1rem!important">
            <Box fontWeight={600} fontFamily="lato" fontSize={"16px"}>
              Nom :
            </Box>
            <Box
              textTransform={"uppercase"}
              fontSize="16px"
              fontFamily={"lato"}
            >
              Nom Utilisateur
            </Box>
          </Box>
          <Box display={"flex"} flexDirection="row" gap="1rem!important">
            <Box fontWeight={600} fontFamily="lato" fontSize={"16px"}>
              Adresse :
            </Box>
            <Box fontSize="16px" fontFamily={"lato"}>
              Station F
            </Box>
          </Box>
          <Box
            fontSize={"20px"}
            fontWeight={600}
            color="rgb(102, 102, 102)"
            margin="8px 0px 0px"
          >
            Contact
          </Box>
          <Box display={"flex"} flexDirection="row" gap="1rem!important">
            <Box fontWeight={600} fontFamily="lato" fontSize={"16px"}>
              Email:
            </Box>
            <Box fontSize="16px" fontFamily={"lato"}>
              <Link to={""} style={{ color: "#0d6efd" }}>
                tefymaherison19@gmail.com
              </Link>
            </Box>
          </Box>
        </Box>
      </Box> */}

      {/* Applications */}
      {/* <Box>
        <Box
          color="rgb(102, 102, 102)"
          fontFamily={"lato"}
          fontSize="1.2rem"
          fontWeight={600}
        >
          Applications
        </Box>
        <Box
          display="block"
          alignItems={"center"}
          fontSize={"16px"}
          padding="0px 0px 5px"
          fontFamily="lato"
        >
          Pour se connecter en 1 clic
        </Box>
        <Box>
          <Link to={"path"} onClick={redirectToFranceSanteConnect}>
          <img
            style={{ width: "200px", height: "56px", color: "black" }}
            src={ProSanteConnect}
            alt="test"
          /></Link>
        </Box>
      </Box> */}
      {/* création d'une reservation */}
      <Box>
        <Box
          color="rgb(102, 102, 102)"
          fontFamily={"lato"}
          fontSize="1.2rem"
          fontWeight={600}
        >
          Création d'une reservation
        </Box>
        <Box display={"flex"} justifyContent="row" gap=".5rem!important">
          <Box>
            <Checkbox
              style={{
                height: "12px",
                color: "black",
                width: "13px",
                border: "1px ",
                margin :"0",
                borderRadius: "5px",
              }}
            />
          </Box>
          <Box>
            Utiliser l'adresse du service comme adresse par défaut pour les
            réservations à venir.
          </Box>
        </Box>
      </Box>
      {/*Ecran d'urgence */}
      {/* <Box>
        <Box
          color="rgb(102, 102, 102)"
          fontFamily={"lato"}
          fontSize="1.2rem"
          fontWeight={600}
        >
          Ecran d'urgence
        </Box>
        <Box fontSize={"16px"} fontFamily="lato">
          Si vous etes un établissement de santé, sachez que vous avez accès à
          notre{" "}
          <Link to={""} style={{ color: "#0d6efd" }}>
            {" "}
            écran des urgences
          </Link>{" "}
          dédié
        </Box>
      </Box> */}
    </Box>
  );
};

export default Settings;
