import { FC } from "react";
import { Box } from "@mui/material";
import QRCodeComponent from "../../components/QrCode";
import OCRScanner from "../../components/OCRScanner";


const testQrCode : FC=() =>{
return (
    <Box> <QRCodeComponent /><OCRScanner />
    </Box>
)

}
export default testQrCode;